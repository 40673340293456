import { modalConstants } from '../constants';

const initialState = {
  showVerificationModal: false,
};

export function modal(state = initialState, action) {
  switch (action.type) {
    case modalConstants.HIDE_VERIFICATION_MODAL:
      return {
        showVerificationModal: false,
      };
    case modalConstants.SHOW_VERIFICATION_MODAL:
      return {
        showVerificationModal: true,
      };

    default:
      return state;
  }
}
