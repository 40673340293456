import React from 'react';
import Button from 'react-bootstrap/Button';
import dashedLine from '../../images/dashed-line.png';
import headerMobileBg from '../../images/header-mobile-bg.png';
import headerBottomImg from '../../images/header-bottom-img.png';
import mobileImg from '../../images/mobile-img.png';
import mobileResponsive from '../../images/mobile-responsive-img.svg';
import setAndForget from '../../images/set-and-forget-img.svg';
import setAndForgetResponsive from '../../images/set-forget-responsive.svg';
import lightOrangeShape from '../../images/light-orange-vector.svg';
import dollarCostAverage from '../../images/dollar-cost-average-img.svg';
import dollarCostAverageResponsive from '../../images/dollar-cost-average-responsive-img.svg';
import byMore from '../../images/by-more-img.svg';
import byMoreResponsive from '../../images/buy-more-responsive.png';
import securitySerious from '../../images/security-serious-img.svg';
import securitySeriousTop from '../../images/orange-vector.png';
import faq from '../../images/faq-img.svg';
import yellowRectangle from '../../images/yellow-rectangle.svg';
import orangeTriangle from '../../images/orange-triangle.svg';
import blueCircle from '../../images/blue-circle.svg';
import blueRectangle from '../../images/blue-rectangle.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillAlt } from '@fortawesome/free-regular-svg-icons';
import {
  faSignInAlt,
  faLink,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import { faBtc } from '@fortawesome/free-brands-svg-icons';
import Form from 'react-bootstrap/Form';
import FAQComponent from '../../components/FAQComponent/FAQComponent';
import { useForm } from 'react-hook-form';
import { storeEmail } from '../../firebase/firebase.utils';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './HomePage.scss';

function HomePage(props) {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data, event) => {
    event.target.reset();
    storeEmail(data.email).then(() =>
      toast.success('Your email is sent successfully.')
    );
  };
  const errorClass = function (error) {
    return error && error.message !== '' ? 'border border-danger' : '';
  };

  const faqList = [
    {
      id: 1,
      question: 'Which banks can I conncet to?',
      answer:
        'Duppy uses Plaid for access to your bank account and can therefore connect to all the large banks in the Netherlands. Fa. ING, Rabobank, ABN Amro, Regiobank, Bunq',
    },
    {
      id: 2,
      question: 'Why does Duppy need my bank login information?',
      answer:
        'We need it to verify you as a person, which is obligated by the European Union. Also we use your bank login information to get ongoing transaction data, which is only used to calculate spare change. Transaction data is securely handled by Plaid and we do not store your personal information.',
    },
    {
      id: 3,
      question: 'Where are my crypto currencies/coins storeds?',
      answer:
        'Duppy uses a decentralized exchange that is fully compliant with all the regulations and therefore one of the safest solutions in the environment.',
    },
    {
      id: 4,
      question:
        'Can I make recurring monthly contributions to save/invest more?',
      answer:
        'You can choose to collect 1 euro a day, or 10 euro’s a week next to your round-ups.',
    },
    {
      id: 5,
      question: 'Can I control my Rundup activity?',
      answer:
        'Ofcourse. In the app you can see all your transactions that were used for the round-up option. Whenever you want to stop for a while you can freeze the function.',
    },
    {
      id: 6,
      question: 'Can Duppy access my funds?',
      answer:
        'No. Duppy can only spend the euro’s provided from the round-ups. We can not access your bank account in any other way. Also, we are not able to spend or transfer your Bitcoin in any way.',
    },
    {
      id: 7,
      question: 'What are the fees?',
      answer:
        'Duppy charges a monthly fee of 1 euro. For the Bitcoin purchase we charge 1% transaction fee. However we are more than competitive with the other exchanges or brokers. Find it out for yourself!',
    },
    {
      id: 8,
      question: 'How does Duppy calculate spare change?',
      answer:
        'We look at the purchase price on your transaction history and rounds up to the next euro. In the case of a whole euro purchase price, we record the spare change as a full euro (fa. a purchase of 5 euro generates 1 euro in spare change).',
    },
    {
      id: 9,
      question: 'Does Duppy buy bitcoin every time I make a purchase?',
      answer:
        'No. In an effort to minimize the exchange fees, the spare change from your transactions is accumulated until you reach a minimum of 5 euro’s. Once a week we see it you reached the minimum amount. Only after reaching the minimum we withdraw the spare change from your bank account and invest it in Bitcoin.',
    },
  ];
  return (
    <div className='container-fluid homepage-container my-0 px-0'>
      <ToastContainer
        position='top-right'
        autoClose={3500}
        hideProgressBar={true}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        pauseOnHover
      />
      <header>
        <div className='header-top pt-lg-5 pt-3 w-100'>
          <div className='large-container'>
            <div className='text-left'>
              <h1 className='text-orange fs-2'>DUPPY</h1>
              <p className='fs-6 d-lg-block d-none'>
                Where small change makes a big difference.
              </p>
            </div>
          </div>
        </div>
        <div className='header-bottom'>
          <div className='large-container'>
            <div className='row'>
              <div className='col-xl-6 col-lg-8 col-12 text-xl-left text-lg-left text-center order-lg-1 order-md-2'>
                <h1 className='fs-1 mb-3'>
                  Easy, fun and low-cost for everyone to invest in
                  <span className='text-orange'> Bitcoin.</span>
                </h1>
                <a
                  href='#sign-up'
                  className='fs-8 d-lg-flex d-none align-items-center justify-content-center btn btn-primary'
                >
                  FIND OUT FIRST
                </a>
              </div>
              <div className='col-xl-6 col-lg-4 col-12 order-lg-2 order-md-1 d-flex justify-content-center'>
                <img
                  src={headerMobileBg}
                  alt='header-mobile-img'
                  className='d-md-block d-lg-none'
                />
              </div>
              <img
                src={yellowRectangle}
                alt='rectangle-img'
                className='position-absolute rectangle-header-position d-xl-block d-none'
              />
            </div>
          </div>
        </div>
        <img
          src={headerBottomImg}
          alt='header-bottom-img'
          className='d-md-block d-lg-none position-absolute w-100 header-bottom-img-position'
        />
      </header>
      <section id='about-us' className='mt-5 position-relative'>
        <div className='large-container'>
          <div className='row'>
            <div className='offset-lg-2 col-lg-8 col-12'>
              <p className='fs-3 px-md-5 px-0 mt-lg-0 mt-5'>
                We are a micro investment app that rounds up your everyday
                spendings to the next euro and invests it in Bitcoin.
              </p>
            </div>
            <div className='offset-lg-1 col-lg-11 col-12 img-container'>
              <img
                src={mobileImg}
                alt='mobile-img'
                className='mt-5 img-fluid d-md-block d-none'
              />
              <img
                src={mobileResponsive}
                alt='mobile-responsive'
                className='mt-5 img-fluid w-100 d-md-none d-inline-block'
              />
            </div>
          </div>
        </div>
        <img
          src={orangeTriangle}
          alt='triangle-img'
          className='position-absolute triangle-about-us-position d-xl-block d-none'
        />
        <img
          src={blueRectangle}
          alt='rectangle-img'
          className='position-absolute rectangle-about-us-position d-xl-block d-none'
        />
      </section>
      <section id='set-and-forget' className='mt-5 position-relative'>
        <div className='large-container'>
          <div className='row'>
            <div className='col-lg-5 col-12'>
              <h2 className='fs-2 text-center d-lg-none d-block'>
                Set it and <span className='fs-2 text-orange'>forget it.</span>
              </h2>
              <img
                src={setAndForget}
                alt='set-and-forget'
                className='img-fluid d-lg-block d-none'
              />
              <img
                src={setAndForgetResponsive}
                alt='set-and-forget-responsive'
                className='img-fluid d-lg-none d-inline-block my-lg-0 my-4'
              />
            </div>
            <div className='col-lg-6 offset-lg-1 col-12 my-auto'>
              <h2 className='fs-2 text-left d-lg-block d-none'>
                Set it and <span className='fs-2 text-orange'>forget it.</span>
              </h2>
              <div className='row mt-4'>
                <div className='col-xl-6 col-lg-12 col-md-6 col-12 order-1'>
                  <div className='d-flex justify-content-start'>
                    <span className='icon-circle d-lg-flex align-items-center justify-content-center d-none'>
                      <FontAwesomeIcon icon={faSignInAlt} />
                    </span>
                    <p className='fs-5 mb-0 ml-3 set-and-forget-subtitle text-left'>
                      1. Download the app or sign up online
                    </p>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-12 col-md-6 col-12 mt-4 order-xl-3 order-lg-2 order-md-3 order-2'>
                  <div className='d-flex justify-content-start align-items-start'>
                    <span className='icon-circle d-lg-flex align-items-center justify-content-center d-none'>
                      <FontAwesomeIcon icon={faLink} />
                    </span>
                    <div className='ml-3'>
                      <p className='fs-5 mb-0 set-and-forget-subtitle text-left'>
                        2. Connect your bank account
                      </p>
                      <p className='fs-8 text-left mb-0'>
                        We support the largest banks in the Netherlands. We got
                        you covered!
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-12 col-md-6 col-12 mt-xl-0 mt-lg-4 mt-md-0 mt-4 order-xl-2 order-lg-3 order-md-2 order-3'>
                  <div className='d-flex justify-content-start'>
                    <span className='icon-circle d-lg-flex align-items-center justify-content-center d-none'>
                      <FontAwesomeIcon icon={faMoneyBillAlt} />
                    </span>
                    <p className='fs-5 mb-0 ml-3 set-and-forget-subtitle text-left'>
                      3. Set-up your account preferences.
                    </p>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-12 col-md-6 col-12 mt-4 order-4'>
                  <div className='d-flex justify-content-start align-items-start'>
                    <span className='icon-circle d-lg-flex align-items-center justify-content-center d-none'>
                      <FontAwesomeIcon icon={faBtc} />
                    </span>
                    <div className='ml-3'>
                      <p className='fs-5 mb-0 set-and-forget-subtitle text-left'>
                        4. Let the app do the work
                      </p>
                      <p className='fs-8 text-left  mb-0'>
                        and invest your spare change in Bitcoin
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src={yellowRectangle}
          alt='rectangle-img'
          className='position-absolute rectangle-set-and-forget-position d-xl-block d-none'
        />
        <img
          src={dashedLine}
          alt='dashed-line'
          className='position-absolute dashed-line-position d-xl-block d-none'
        />
        <img
          src={lightOrangeShape}
          alt='light-orange-shape'
          className='position-absolute light-orange-shape-position d-lg-none d-block'
        />
      </section>
      <section
        id='dollar-cost-average'
        className='mt-5 position-relative pt-lg-0 pt-5'
      >
        <div className='large-container'>
          <div className='row'>
            <div className='col-lg-5 offset-lg-1 col-12 my-auto order-lg-1 order-2'>
              <h2 className='fs-2 text-left d-lg-block d-none'>
                DCA:
                <span className='fs-2 text-orange'> Dollar Cost Average</span>
              </h2>
              <p className='fs-6 text-left'>
                As we know the price of Bitcoin is very volatile.
                <br /> That is why we recommend using DCA. It's a long-term
                strategy where you reduce the risk of your investment. You
                invest periodically (for example every week) a fixed amount
                regardless the value of Bitcoin. This way you invest during the
                highs and lows of the Bitcoin price.
                <br /> We offer:
              </p>
              <div className='d-flex justify-content-start align-items-center mb-4'>
                <span className='fs-5 invest-style py-2'>&euro;1</span>
                <p className='fs-6 text-left mb-0 ml-4'>Invest 1 euro a day</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mb-4'>
                <span className='fs-5 invest-style py-2'>&euro;10</span>
                <p className='fs-6 text-left mb-0 ml-4'>
                  Invest 10 euros a week
                </p>
              </div>
              <p className='fs-6 text-left'>
                Your round-ups will be collected every week as long as you reach
                a minimum of € 5,-
              </p>
            </div>
            <div className='col-lg-5 offset-lg-1 col-12 my-auto order-lg-2 order-1'>
              <h2 className='fs-2 text-center d-lg-none d-block'>
                DCA:
                <span className='fs-2 text-orange'> Dollar Cost Average</span>
              </h2>
              <img
                src={dollarCostAverage}
                alt='dollar-cost-average'
                className='img-fluid d-lg-block d-none'
              />
              <img
                src={dollarCostAverageResponsive}
                alt='dollar-cost-average-responsive'
                className='img-fluid d-lg-none d-inline-block my-lg-0 my-md-5 my-4'
              />
            </div>
          </div>
        </div>
        <img
          src={blueCircle}
          alt='circle-img'
          className='position-absolute circle-position d-xl-block d-none'
        />
        <img
          src={yellowRectangle}
          alt='rectangle-img'
          className='position-absolute rectangle-dollar-cost-position d-xl-block d-none'
        />
      </section>
      <section id='buy-more' className='mt-5 position-relative'>
        <div className='large-container'>
          <div className='row'>
            <div className='col-lg-5 col-12'>
              <h2 className='fs-2 text-center d-lg-none d-block'>
                You can always
                <span className='fs-2 text-orange'> by more.</span>
              </h2>
              <img
                src={byMore}
                alt='by-more'
                className='img-fluid d-lg-block d-none'
              />
              <img
                src={byMoreResponsive}
                alt='by-more'
                className='img-fluid d-lg-none d-inline-block my-lg-0 my-4'
              />
            </div>
            <div className='col-lg-5 offset-lg-1 col-12 my-auto'>
              <h2 className='fs-2 text-left d-lg-block d-none'>
                You can always
                <span className='fs-2 text-orange'> by more.</span>
              </h2>
              <p className='fs-6 text-left pr-lg-5 pr-0'>
                Automation is the best solution for DCA (dollar cost average)
                strategy, but if you have something extra laying around or the
                now. This works the other way around as well. It’s your money /
                Bitcoin so when you want to cash out, you can do it anytime.
                <br /> You are in control!
              </p>
            </div>
          </div>
        </div>
        <img
          src={orangeTriangle}
          alt='triangle-img'
          className='position-absolute triangle-buy-more-position d-xl-block d-none'
        />
        <img
          src={blueCircle}
          alt='circle-img'
          className='position-absolute circle-buy-more-position d-xl-block d-none'
        />
        <img
          src={securitySeriousTop}
          alt='security-serious-top-img'
          className='d-md-block d-lg-none position-absolute w-100 security-serious-top-position'
        />
      </section>
      <section
        id='security-is-serious'
        className='mt-lg-5 mt-0 d-flex align-items-center pb-lg-0 pb-5'
      >
        <div className='large-container'>
          <div className='row'>
            <div className='col-lg-6 offset-lg-1 col-12 my-auto mt-lg-5 order-2 order-lg-1'>
              <h2 className='fs-2 text-white text-left mt-lg-4 mt-0'>
                Security is serious
              </h2>
              <p className='fs-6 text-left text-white'>
                Not only your money, but also your information is very important
                to us. Therefore we only work with the best providers. All under
                control of the DNB. We use Plaid for your information, which is
                also used by American Express, Coinbase, Venmo and others. We
                don’t have access to your financial institution's credentials,
                and can’t save, store, or share this data in any way. For the
                storage of your Bitcoin we use Nash. It is a decentralized
                Exchange and therefore more secure than any other centralized
                exchange. Also Nash is fully compliant with all regulators in
                the EU and US. Your money and information is not stored on your
                phone or computer. If you would lose it, you didn’t lose your
                money.
              </p>
            </div>
            <div className='col-lg-4 offset-lg-1 col-12 my-auto order-1 order-lg-2'>
              <img
                src={securitySerious}
                alt='security-is-serious'
                className='img-fluid'
              />
            </div>
          </div>
        </div>
      </section>
      <section id='faq' className='mt-lg-5 mt-0 position-relative'>
        <div className='large-container'>
          <div className='row'>
            <div className='col-lg-4 col-12 my-lg-auto my-4'>
              <img src={faq} alt='security-is-serious' className='img-fluid' />
            </div>
            <div className='col-lg-6 offset-lg-1 col-12'>
              <h2 className='fs-2 text-lg-left text-center mb-4'>
                Frequently asked
                <span className='fs-2 text-orange'> questions</span>
              </h2>
              {faqList &&
                faqList
                  .filter((x, index) => index < 9)
                  .map((x, index) => <FAQComponent x={x} key={x.id} />)}
            </div>
          </div>
        </div>
        <img
          src={orangeTriangle}
          alt='triangle-img'
          className='position-absolute triangle-faq-position d-xl-block d-none'
        />
        <img
          src={blueCircle}
          alt='circle-img'
          className='position-absolute circle-faq-position d-xl-block d-none'
        />
      </section>
      <section id='sign-up' className='mt-5 d-flex align-items-center'>
        <div className='large-container'>
          <div className='row'>
            <div className='col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-12'>
              <h2 className='fs-2'>
                Be one of <span className='fs-2 text-orange'>the first!</span>
              </h2>
              <p className='fs-6 text-left'>
                Can`t wait to start using Duppy? Us too. Sign up to the waiting
                list for early access and product updates
              </p>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId='formBasicEmail' className='mb-0 d-flex'>
                  <Form.Control
                    className={`fs-8 d-inline-block ${errorClass(
                      errors.email
                    )}`}
                    placeholder='Email adress'
                    type='email'
                    name='email'
                    ref={register({
                      required: 'Email is required.',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'Enter a valid e-mail address',
                      },
                    })}
                  />
                  <Button
                    className='w-100 fs-8 d-inline-block'
                    type='submit'
                    formNoValidate
                  >
                    LET'S GO
                  </Button>
                  <div className='text-danger text-left message-container position-absolute'>
                    {errors.email && errors.email.message}
                  </div>
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className='large-container'>
          <div className='row'>
            <div className='col-xl-3 col-lg-4 text-lg-left text-center col-12'>
              <h1 className='text-orange fs-2'>DUPPY</h1>
              <p className='fs-7'>
                We are a micro investment app that rounds up your everyday
                spendings to the next euro and invests it in Bitcoin.
              </p>
              <p className='fs-8 mt-5 d-lg-block d-none'>
                All rights reserved © 2021 DUPPY
              </p>
            </div>
            <div className='col-xl-6 offset-xl-3 col-lg-8 col-12 my-auto'>
              <div className='row'>
                <div className='col-lg-4 col-12'>
                  <ul className='list-unstyled mt-3 text-lg-left text-center mb-lg-3 mb-0'>
                    <li>
                      <a href='#about-us' className='fs-7'>
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href='#set-and-forget' className='fs-7'>
                        Set it and forget it
                      </a>
                    </li>
                    <li>
                      <a href='#dollar-cost-average' className='fs-7'>
                        Dollar cost average
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='col-lg-3 col-12'>
                  <ul className='list-unstyled text-lg-left text-center mt-lg-3 mt-0 order-4'>
                    <li>
                      <a href='#buy-more' className='fs-7'>
                        Buy more
                      </a>
                    </li>
                    <li>
                      <a href='#security-is-serious' className='fs-7'>
                        Security
                      </a>
                    </li>
                    <li>
                      <a href='#faq' className='fs-7'>
                        FAQ
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='col-lg-5 col-12 my-auto'>
                  <div className='text-lg-left text-center mb-lg-0 mb-3'>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className='text-orange mr-3'
                    />
                    <a href='mailto:info@duppyapp.com' className='fs-7'>
                      info@duppyapp.com
                    </a>
                  </div>
                </div>
                <div className='col-12 d-lg-none d-block'>
                  <p className='fs-8 text-center rights-style pt-3'>
                    All rights reserved © 2021 DUPPY
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default HomePage;
