import React from 'react';
import './App.scss';
import Routes from '../src/routes/routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import { loginUser } from '../src/firebase/firebase.utils';

function App() {
  loginUser();
  return (
    <div className='App'>
      <Routes />
    </div>
  );
}

export default App;
