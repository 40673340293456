import { combineReducers } from 'redux';

import { modal } from './modal.reducer';

const appReducer = combineReducers({
  modal,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
