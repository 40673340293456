import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import dotenv from 'dotenv';

dotenv.config();
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

export const storeEmail = async (email) => {
  if (!loginUser()) return;

  return firestore.collection('emails').doc().set({ email });
};

export const loginUser = async () =>
  await auth.signInWithEmailAndPassword(
    process.env.REACT_APP_FIREBASE_USER_EMAIL,
    process.env.REACT_APP_FIREBASE_USER_PASSWORD
  );
