import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import './FAQComponent.scss';

function FAQComponent(props) {
  const { x } = props;
  const [questionIsOpen, setQuestionIsOpen] = useState(false);

  const toggleQuestion = () => {
    setQuestionIsOpen(!questionIsOpen);
  };
  return (
    <div className='faq-container'>
      <div className='row'>
        <div className='col-10'>
          <p className='fs-6 text-left mb-0 py-3'>{x.question}</p>
        </div>
        <div className='col-2 my-auto text-right'>
          <span
            className='ml-auto bg-transparent border-0'
            data-target='#question-collapse'
            onClick={toggleQuestion}
          >
            {questionIsOpen ? (
              <FontAwesomeIcon icon={faMinus} className='text-orange' />
            ) : (
              <FontAwesomeIcon icon={faPlus} className='text-orange' />
            )}
          </span>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div
            id='question-collapse'
            className={`collapse ${questionIsOpen ? 'd-block' : 'd-none'}`}
          >
            <p className='fs-8 text-left'>{x.answer}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQComponent;
