import React from 'react';
import plantLeftImg from '../../images/left-plants-img.svg';
import plantRightImg from '../../images/right-plants-img.svg';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import './NotFoundPage.scss';

function NotFoundPage(props) {
  return (
    <div className='d-flex justify-content-center align-items-center not-found-page'>
      <div>
        <h1 className='fs-2 mb-lg-5 mb-md-4 mb-3 text-orange'>
          PAGE NOT FOUND
        </h1>
        <div className='mx-auto mt-4 mb-3'>
          <FontAwesomeIcon
            className='mx-auto mt-4 mb-3 text-orange fa-4x'
            icon={faExclamationTriangle}
          />
        </div>
        <Link to='/' className='d-block mt-3 orange-text'>
          Back to home
        </Link>
      </div>
      <img
        src={plantLeftImg}
        className='position-absolute plants-left-bg-image'
        alt='plants-left'
      />
      <img
        src={plantRightImg}
        className='position-absolute plants-right-bg-image'
        alt='plants-right'
      />
    </div>
  );
}

export default NotFoundPage;
