import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import HomePage from '../pages/HomePage/HomePage';
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage';
import { history } from '../helpers/history';

export default function Routes(props) {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path='/' component={() => <HomePage />} />
        <Route path='*' component={NotFoundPage} />
      </Switch>
    </Router>
  );
}
